import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Body, Block, BodyLarge } from '@thesisedu/web/dist'
import { Avatar } from 'antd'
import Img from 'gatsby-image'
import { BrochureCard, BrochureCardSmall } from '../styled'
import { TestimonialPieceFragment } from '../../graphqlTypes'

export const fragment = graphql`
  fragment TestimonialPiece on File {
    id
    childMarkdownRemark {
      html
      frontmatter {
        avatar {
          childImageSharp {
            fixed(height: 86, width: 86) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        isFeatured
        name
        position
        school
        state
      }
    }
  }
`

export interface TestimonialProps {
  testimonial: TestimonialPieceFragment
}
export function Testimonial({ testimonial }: TestimonialProps) {
  const { frontmatter, html } = testimonial.childMarkdownRemark
  const ft = !!frontmatter.isFeatured
  const nameFragments = frontmatter.name
    .split(' ')
    .map((i) => i[0].toUpperCase())
    .join('')
  return (
    <Container as={ft ? BrochureCard : BrochureCardSmall} className={ft ? 'featured' : ''}>
      <AvatarBackground as={ft ? BrochureCard : BrochureCardSmall} />
      <AvatarContainer>
        <Avatar
          size={86}
          src={
            frontmatter.avatar ? (
              <Img fixed={frontmatter.avatar.childImageSharp.fixed} alt={frontmatter.name} />
            ) : undefined
          }
        >
          {frontmatter.avatar ? undefined : nameFragments}
        </Avatar>
      </AvatarContainer>
      <div className={'content'}>
        <BodyLarge color={'@text-color-secondary'}>{frontmatter.name} says...</BodyLarge>
        <Block marginTop={'@size-xs'}>
          <BodyLarge useDiv className={'message'} dangerouslySetInnerHTML={{ __html: html }} />
        </Block>
      </div>
      <Footer>
        <Body color={'@text-color-secondary'}>
          {frontmatter.school}, {frontmatter.state}
        </Body>
      </Footer>
    </Container>
  )
}

const Footer = styled.div`
  border-bottom-left-radius: ${(props) => props.theme['@border-radius-large']};
  border-bottom-right-radius: ${(props) => props.theme['@border-radius-large']};
  background: ${(props) => props.theme['@background-color-base']};
  padding: ${(props) => props.theme['@size-m']} ${(props) => props.theme['@size-l']}
    ${(props) => props.theme['@size-l']} ${(props) => props.theme['@size-l']};
`
const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: visible;
  .content {
    padding: calc(${(props) => props.theme['@size-s']} + (86px / 2))
      ${(props) => props.theme['@size-l']} ${(props) => props.theme['@size-l']}
      ${(props) => props.theme['@size-l']};
  }
  .message {
    font-size: ${(props) => props.theme['@size-m']} !important;
    line-height: 1.6 !important;
    p {
      margin: 0;
    }
  }
  &.featured .message {
    font-size: 28px !important;
    line-height: 1.5 !important;
  }
`
const AvatarBackground = styled.div`
  position: absolute;
  left: ${(props) => props.theme['@size-l']};
  top: calc((-86px - 10px) / 2);
  width: calc(86px + 10px);
  height: calc(86px + 10px);
  border-radius: 50%;
  z-index: -1;
`
const AvatarContainer = styled.div`
  position: absolute;
  left: calc(${(props) => props.theme['@size-l']} + 5px);
  top: calc((-86px) / 2);
  width: 86px;
  height: 86px;
  background: ${(props) => props.theme['@gray-0']};
  border-radius: 50%;
`
