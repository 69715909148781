import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Masonry from 'react-masonry-css'
import styled from 'styled-components'
import { BREAKPOINTS } from '@thesisedu/web/dist'
import { TestimonialsQuery } from '../../graphqlTypes'
import { Testimonial } from './Testimonial'

const query = graphql`
  query testimonials {
    content: allFile(
      sort: { order: [ASC], fields: [childMarkdownRemark___frontmatter___weight] }
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        childMarkdownRemark: { frontmatter: { isFeatured: { ne: true } } }
        extension: { eq: "md" }
      }
    ) {
      edges {
        node {
          ...TestimonialPiece
        }
      }
    }
  }
`

export function TestimonialsList() {
  const data = useStaticQuery<TestimonialsQuery>(query)
  const testimonials = data.content.edges.map((edge) => edge.node)
  return (
    <Container
      breakpointCols={{ default: 3, [BREAKPOINTS.md]: 2, [BREAKPOINTS.sm]: 1 }}
      className={''}
      columnClassName={'grid-column'}
    >
      {testimonials.map((t) => (
        <Testimonial testimonial={t} key={t.id} />
      ))}
    </Container>
  )
}

const Container = styled(Masonry)`
  display: flex;
  margin-left: -${(props) => props.theme['@size-l']};
  width: auto;
  .grid-column {
    padding-left: ${(props) => props.theme['@size-l']};
    padding-top: ${(props) => props.theme['@size-xxl']};
    > div {
      margin-bottom: ${(props) => props.theme['@size-section-md']};
    }
  }
`
