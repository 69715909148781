import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { FeaturedTestimonialQuery } from '../../graphqlTypes'
import { Testimonial } from './Testimonial'

const query = graphql`
  query featuredTestimonial {
    content: allFile(
      sort: { order: [ASC], fields: [name] }
      limit: 1
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        childMarkdownRemark: { frontmatter: { isFeatured: { eq: true } } }
      }
    ) {
      edges {
        node {
          ...TestimonialPiece
        }
      }
    }
  }
`

export function FeaturedTestimonial() {
  const data = useStaticQuery<FeaturedTestimonialQuery>(query)
  const testimonial = data.content.edges[0]?.node
  if (testimonial) {
    return (
      <Container>
        <Testimonial testimonial={testimonial} />
      </Container>
    )
  }
  return null
}

const Container = styled.div`
  max-width: 375px;
`
