import React from 'react'
import { H1, BodyLarge, Block } from '@thesisedu/web/dist'

export function ScheduleDemoEmail() {
  return (
    <>
      <Block style={{ textAlign: 'center' }}>
        <H1>Schedule a Demo</H1>
      </Block>
      <BodyLarge style={{ textAlign: 'center' }}>
        Email <a href={'mailto:sales@prodigiesacademy.com'}>sales@prodigiesacademy.com</a> to
        schedule your demo! During the screen-share demo we will give you a tour of the Prodigies
        Academy platform and answer any questions you may have!
      </BodyLarge>
    </>
  )
}
